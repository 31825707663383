<template >
  <b-container fluid>
    <loading :active.sync="isWorkingAdmin" :is-full-page="true"></loading>
    <b-row class="margin-row" v-if="!isBusquedaByCurp">
      <b-col lg="5" md="6" sm="9" cols="10" v-if="porRevisarInfo.tramites.length > 0">
        <b-form-group >
            <b style="font-size:90%;">Filtrar por nombre:</b>
            <b-form-input size="sm" type="search" id="filterInput" placeholder="Nombre de contribuyente" ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col style="margin-left:-30px; margin-top:-6px;" sm="3" cols="2" v-if="porRevisarInfo.tramites.length > 0">
        <b-button size="sm" class="button-filtrar" variant="info  " @click="filtrarTramites"><b-icon rotate="45" font-scale="1" icon="cursor-fill"/></b-button>
      </b-col>
    </b-row>
    <b-row class="margin-row">
      <b-col lg="12">
        <b-card class="c-shadow-sm">
          <b-table :filter="filter" responsive striped small sticky-header :items="porRevisarInfo.tramites" :fields="fieldsTableTramites" class="table-style" sort-icon-left>
            <template v-slot:cell(nombreContrib)="row">
              <div style="cursor:pointer;" @click="showPanelConfigTramite(row.item.idUserService, row.item.nombreContrib)">
                <b-icon icon="person-circle"/>
                <b style="font-size:90%; text-transform:capitalize;"> {{row.item.nombreContrib.toLowerCase()}}</b>
              </div>              
            </template>
            <template v-slot:cell(ultMov)="row">
              <div style="cursor:pointer;" @click="showPanelConfigTramite(row.item.idUserService, row.item.nombreContrib)">
                <b style="font-size:90%; text-transform:capitalize;"> {{row.item.ultMov}}</b>
              </div>              
            </template>
          </b-table>
          <b-table v-show="false" id="table-tramites" :filter="filter" responsive striped small sticky-header :items="porRevisarInfo.tramites" :fields="fieldsTableTramitesToExport" class="table-style"></b-table>
        </b-card>
      </b-col>
    </b-row>
    <b-button pill size="sm" class="button-excel" variant="secondary" @click="generateExcel" v-if="!isBusquedaByCurp && porRevisarInfo.tramites.length > 0"><b-icon icon="file-spreadsheet"></b-icon> Exportar a excel</b-button>

    <!--modals-->
    <b-modal  v-model="isShowModalConfigTramite" :title="titleModalConfigTramite" size="lg" hide-footer>
      <div style="margin-left:-16px; margin-right:-16px; margin-top:-80px;" class="container-head">
        <div class="bg-head-modal">
        </div>
      </div>
      <b-row align-h="center" style="margin-top:-200px;">
        <b-col align="left" ><img  height="30px;" :src="'/imgs/logosmpios/'+codeClient+'.png'" /></b-col>
        <b-col align="right"><b-button pill size="sm" variant="warning" @click="isShowModalConfigTramite=false"><b-icon icon="arrow-left-circle"/> Regresar</b-button></b-col>
      </b-row>
      <panelConfigTramite/>
    </b-modal>
  </b-container>
</template>

<script>
  import { mapState} from 'vuex';
  import panelConfigTramite from './panelConfigTramite.vue';
  import { currentTramiteAdmin } from '@/axios-tools';
  import { datatBynaryToOctet } from '@/vue-tools';
  import XLSX from 'xlsx';
  import { saveAs } from 'file-saver';
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import Vue from 'vue';
  import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
  Vue.use(BootstrapVue)
  Vue.use(BootstrapVueIcons)

  export default {
    computed: {
      ...mapState({
        token: state => state.admin.token,
        porRevisarInfo: state => state.admin.porRevisarInfo,
        isWorkingAdmin: state => state.admin.isWorkingAdmin,
        isBusquedaByCurp: state => state.admin.isBusquedaByCurp
      }),
      codeClient:function(){
        return this.$route.params.municipio;
      },
      isShowModalConfigTramite: {
        get: function() {
          return this.$store.state.admin.isShowModalConfigTramite;
        },
        set: function(newIsShowModalConfigTramite) {
          this.$store.commit("updateIsShowModalConfigTramite", newIsShowModalConfigTramite);
        }
      },
    },
    components:{
      panelConfigTramite,
      Loading
    },
    data() {
      return {
        cliente: location.pathname.split("/", 2).toString().replace(",", ""),
        titleModalConfigTramite:null,
        filter:"",
        fieldsTableTramites:[{key: 'nombreContrib',label: 'Nombre', sortable:true},{key: 'ultMov',label: 'Recibido', sortable:true},],
        fieldsTableTramitesToExport:[{key: 'nombreContrib',label: 'Nombre'},{key: 'ultMov',label: 'Recibido'}]
      }
    },
    created: function () {
      
    },
    methods: {
      generateExcel(){
        var wb = XLSX.utils.book_new();
        wb.SheetNames.push("Tramites");
        var tabla = document.getElementById('table-tramites');
        var ws = XLSX.utils.table_to_sheet(tabla);
        wb.Sheets["Tramites"] = ws;
        var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});
        saveAs(new Blob([datatBynaryToOctet(wbout)],{type:"application/octet-stream"}), 'tramites.xlsx');
      },
      showPanelConfigTramite(idUserService, nombreContrib){
        this.titleModalConfigTramite = nombreContrib;
        currentTramiteAdmin(this.cliente, this.token, idUserService, this.porRevisarInfo.idServiceInfo, this.$store, this.$router);
      },
      filtrarTramites(){
        this.filter = document.getElementById('filterInput').value;
      }
    },
    validations: {
        
    }
  }
</script>
<style>
.button-excel{
  margin-top:15px;
}
.button-filtrar{
  margin-top:30px;
}
.margin-row{
  margin-bottom: 15px;
  margin-left: -40px;
  margin-right: -40px;
}
</style>