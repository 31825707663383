<template>
  <b-container fluid>
    <loading :active.sync="isWorkingAdmin" :is-full-page="true"></loading>
    <loading :active.sync="isWorkingAdminModals" :is-full-page="true"></loading>
    <b-row align="center"><b-col><h4 style="text-transform:capitalize; color:white; margin-top:15px;"><img style="margin-top:-7px;" height="20px;" :src="'../imgs/' + infoCurrentTramite.icon" /> {{infoCurrentTramite.nombreTramite.toLowerCase()}}</h4><br></b-col></b-row> 
    <b-row>
      <b-col>
        <div class="c-shadow-sm" style="margin-left:-22px; margin-right:-22px; padding:15px;" > 
          <b-row >
            <b-col cols="12"><b class="text-md"><b-icon icon="ui-checks-grid"/> Expediente: <font size="2" style="color:green;">GBNT-{{infoCurrentTramite.idUserService}}</font></b></b-col>
            <b-col cols="12"><b class="text-md"><b-icon icon="list-stars"/> Estado: <font size="2" style="color:gray;">{{infoCurrentTramite.estado}}</font></b><br></b-col>
            <b-col cols="12" style="margin-left:-10px;"><b-button v-if="infoCurrentTramite.estado != 'INICIADO' && infoCurrentTramite.transaccional"  @click="mostrarSolicitudAdmin" size="sm" variant="link"  ><b-icon icon="file-text"/> Descargar Solicitud</b-button></b-col>
          </b-row>
          <b-row no-gutters align-h="end" align="right"><b-col  cols="12" ><span @click="getCurrentService()" style="color:#6262E8; cursor:pointer;">Actualizar <b-icon font-scale=".9" icon="arrow-repeat" /></span><br><br></b-col></b-row>
          <b-tabs justified>
            <b-tab >
              <template #title >                
                <span style="font-size:90%;"><b-icon scale=".9" icon="file-earmark-text"></b-icon> Información</span>
              </template>
              <!-----------------------------------------------------------------REQUISITOS------------------------------------------------------------->
              <b-row >
                <b-col>
                  <b-button block v-b-toggle:collapse-1 :style="btnCollapseStepActive">
                    <b-icon shift-v="-3"  font-scale="2" icon="check-circle-fill" variant="success"/> 1 - Envío de Requisitos
                    <span class="when-open"> <b-icon font-scale=".9" shift-v="2" icon="caret-up-fill"/></span> 
                    <span class="when-closed"> <b-icon font-scale=".9" shift-v="2" icon="caret-down-fill"/></span> 
                  </b-button> 
                  <b-row>
                    <b-col cols="1" class="conector-step-active"></b-col>
                    <b-col >
                      <b-collapse id="collapse-1" style="width: 100%; " accordion="my-accordion" role="tabpanel">
                        <div  v-if="infoCurrentTramite.requisitos.length > 0">
                            <br>
                            
                            <div  v-for="requisito in infoCurrentTramite.requisitos" :key="requisito.idRequisito">
                              <b-row >
                                <!-- Nombre -->
                                <b-col cols="12">
                                  <b style="font-size:90%; text-transform:capitalize;">{{requisito.nombreRequisito.toLowerCase()}} </b>
                                  <b-icon v-if="requisito.estado=='ACEPTADO'"  style="color:#6262E8;" font-scale="1.5" shift-v="-2" icon="check2"/><span class="text-req-opcional" v-if="requisito.opcional">**</span>
                                </b-col>    

                                <div v-if="infoCurrentTramite.transaccional">
                                  <!-- Descargar File -->
                                  <b-col cols="12" v-if="requisito.tipo == 'ARCHIVO' && (requisito.estado=='POR REVISAR' || requisito.estado=='ACEPTADO')">
                                    <b-link  size="sm" @click="downloadRequisito(requisito.idRequisito, requisito.nombreRequisito)">Descargar <b-icon font-scale="1" icon="eye-fill"/></b-link>
                                  </b-col>
                                  <!-- Form -->
                                  <b-col cols="12" v-if="requisito.tipo == 'FORMULARIO' && (requisito.estado=='POR REVISAR' || requisito.estado=='ACEPTADO')">
                                    <b-form-input size="sm" class="input-contenido-req" disabled  v-model.trim="requisito.contenido"></b-form-input>
                                  </b-col>
                                  <!-- Mapa -->
                                  <b-col cols="12" v-if="requisito.tipo == 'MAPA' && (requisito.estado=='POR REVISAR' || requisito.estado=='ACEPTADO')">
                                    <b-button block size="sm"   @click="verModalMapaRequisito()">Indicar en el mapa <b-icon icon="geo-alt-fill"/></b-button>
                                  </b-col>
                                  <!-- Select estado-->            
                                  <b-col cols="12" style="margin-top:0px;" v-if="requisito.estado!='ACEPTADO'">
                                    <b-form-select size="sm" :disabled="infoCurrentTramite.estado != 'POR REVISAR' || (requisito.estado != 'POR REVISAR' && requisito.estado != 'ACEPTADO') " v-model="requisito.estado" :options="solicitudOptions" @input="showModalConfirmChangeStatusReq(requisito.idRequisito)"></b-form-select>                                  
                                  </b-col>                
                                </div>
                              </b-row>              
                              <br>
                            </div>
                            <b-col v-if="!infoCurrentTramite.transaccional">
                              <br>
                              <h6 style="color:gray;">Los requisitos se recibieron de manera presencial, consulte el expediente digital</h6>
                            </b-col>
                            <br>

                          <!------------ OBSERVACIONES -------------->
                          <div v-if="infoCurrentTramite.transaccional">
                            <b-form-checkbox v-model="verObs" :disabled="infoCurrentTramite.estado != 'POR REVISAR' && infoCurrentTramite.estado != 'INICIADO'">
                              <h6>Mostrar Observaciones</h6>
                            </b-form-checkbox> 
                            <b-row >
                              <b-col>
                                <div v-if="verObs">
                                    <div v-if="infoCurrentTramite.linkFirmaObs==null">
                                      <b-form-textarea   v-model.trim="infoCurrentTramite.obsRequisitos"></b-form-textarea>
                                      <b-form-file accept="application/pdf" style="margin-top:5px; margin-bottom:5px;" v-model="fileObs" @change="saveB64Obs" placeholder="Selecciona un archivo"></b-form-file>
                                      <b-button size="sm" v-if="fileObs!=null" variant="link" @click="fileObs=null">Quitar archivo</b-button><br>
                                      <b-button v-if="fileObs==null"  :disabled="infoCurrentTramite.obsRequisitos==null || infoCurrentTramite.obsRequisitos.length<5 || infoCurrentTramite.obsRequisitos.length>200" variant="info"  @click="guardarObsReqsTram('txt')">Guardar <b-icon icon="arrow-right-circle"/></b-button>
                                      <b-dropdown split v-if="fileObs!=null" variant="info" @click="guardarObsReqsTram('txt_file_sign')">
                                        <template #button-content>
                                          Firmar y Guardar <b-icon icon="pen" aria-hidden="true"></b-icon>
                                        </template>
                                        <b-dropdown-item @click="guardarObsReqsTram('txt_file')">Enviar sin firma <b-icon icon="arrow-right-circle"/></b-dropdown-item>
                                      </b-dropdown> 
                                    </div>       
                                    <div v-if="infoCurrentTramite.linkFirmaObs!=null">
                                      <br>
                                      <b style="font-size:105%;">Tienes un documento pendiente por firmar</b>
                                      <b-button style=" margin-top:-2px;" size="sm" variant="link" @click="infoCurrentTramite.linkFirmaObs=null">Eliminar documento</b-button><br>
                                      <b-button style=" margin-top:12px;" variant="info"  @click="mostrarModalFirma(infoCurrentTramite.linkFirmaObs)">Firmar Documento <b-icon icon="pen"/></b-button>
                                    </div>                            
                                  <br><br>
                                </div>

                                <div style="border-bottom: 1px gray solid;"/>
                              </b-col>
                            </b-row>
                            <br>
                          </div>

                          <!---------------- YA NO SE HACEN NOTIFICACIONES MANUALES
                          <b-row align-h="center">
                            <b-button size="lg" :disabled="infoCurrentTramite.estado != 'POR REVISAR' && infoCurrentTramite.estado != 'INICIADO'" variant="success" @click="notificarCambios('R')">Notificar Cambios</b-button>
                          </b-row>-------------------->
                        </div>
                      </b-collapse>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <!-------------------------------------------------------------------PAGO-------------------------------------------------------------->
              <b-row  v-if="infoCurrentTramite.estado == 'INICIADO' || infoCurrentTramite.estado == 'POR REVISAR'">
                <b-col cols="12">
                  <b-button disabled :style="btnCollapseStepInactive"><b-icon shift-v="-3"  font-scale="2" icon="check-circle-fill" variant="secondary"/> 2 - Realizar Pago
                    <span class="when-closed"> <b-icon font-scale=".9" shift-v="2" icon="caret-down-fill"/></span>
                  </b-button>
                </b-col>
                <b-col cols="1" class="conector-step-inactive"></b-col>
              </b-row>
              <b-row v-if="infoCurrentTramite.estado != 'INICIADO' && infoCurrentTramite.estado != 'POR REVISAR'" >
                <b-col>
                  <b-button block v-b-toggle="'collapse-2'" :style="btnCollapseStepActive">
                    <b-icon shift-v="-3"  font-scale="2" icon="check-circle-fill" variant="success"/> 2 - Realizar Pago
                    <span class="when-open"> <b-icon font-scale=".9" shift-v="2" icon="caret-up-fill"/></span> 
                    <span class="when-closed"> <b-icon font-scale=".9" shift-v="2" icon="caret-down-fill"/></span>
                  </b-button>
                  <b-row>
                    <b-col v-if="infoCurrentTramite.estado != 'INICIADO' && infoCurrentTramite.estado != 'POR REVISAR'" cols="1" class="conector-step-active"></b-col>
                    <b-col>
                      <b-collapse id="collapse-2" style="width: 100%;" accordion="my-accordion" role="tabpanel">
                        <br>
                        <div>  
                          <b-row v-if="infoCurrentTramite.estado == 'PAGADO'">
                            <b-col cols="12" lg="4">Estado del Pago: 
                              <font v-if="infoCurrentTramite.pago.estadoPago == 'PENDIENTE'" color="CF3737">{{infoCurrentTramite.pago.estadoPago}}</font>
                              <font v-if="infoCurrentTramite.pago.estadoPago == 'PAGADO'" color="289E56">{{infoCurrentTramite.pago.estadoPago}}</font>
                            </b-col>
                            <b-col cols="12" lg="4">Fecha Pago: <font color="289E56">{{infoCurrentTramite.pago.fecPago}}</font></b-col>
                            <b-col cols="12" lg="4">No. recibo: <font color="289E56">{{infoCurrentTramite.pago.noRecibo}}</font></b-col>
                          </b-row> 
                          
                          <b-form-row class="row-input-orden-pago" v-if="infoCurrentTramite.estado == 'PENDIENTE PAGO'"> 
                            <b-col >
                              <h6>Formato de Pago Referenciado:</h6> 
                              <div class="error" v-if="($v.serie.$model==null || $v.serie.$model=='') && infoCurrentTramite.pago.ordenAutomatica">(El usuario aún no genera el formato de pago)</div>
                              <div class="error" v-if="($v.serie.$model==null || $v.serie.$model=='') && !infoCurrentTramite.pago.ordenAutomatica">(Debe ingresar un formato de pago válido)</div>
                            </b-col>
                          </b-form-row> 
                          <b-form-row class="row-input-orden-pago" v-if="infoCurrentTramite.estado == 'PENDIENTE PAGO'">            
                            <b-col xl="4" sm="12">              
                              <b-form-input :disabled="infoCurrentTramite.pago.ordenAutomatica" style="text-transform: uppercase" type="text" :state="!$v.serie.$invalid" v-model.trim="$v.serie.$model" placeholder="Serie"/>
                              <div class="error" v-if="!$v.serie.minLength">La Serie es demasiado corta</div>
                              <div class="error" v-if="!$v.serie.maxLength">La Serie es demasiado larga</div>
                              <div class="error" v-if="!$v.serie.required">Este campo es obligatorio.</div>
                            </b-col>
                            <b-col xl="4" sm="12">
                              <b-form-input :disabled="infoCurrentTramite.pago.ordenAutomatica" type="number" :state="!$v.folio.$invalid" v-model.trim="$v.folio.$model" placeholder="Folio"/>
                              <div class="error" v-if="!$v.folio.minLength">El Folio es demasiado corta</div>
                              <div class="error" v-if="!$v.folio.maxLength">El Folio es demasiado larga</div>
                              <div class="error" v-if="!$v.folio.required">Este campo es obligatorio.</div>
                            </b-col>
                            <b-col xl="4" sm="12">
                              <b-button  v-if="!infoCurrentTramite.pago.ordenAutomatica" :disabled="$v.serie.$invalid || $v.folio.$invalid" class="button-registrar-orden"  variant="primary" @click="updateOrdenPago">Guardar y Notificar</b-button>              
                            </b-col>
                          </b-form-row><br>          
                        </div>
                        <br>
                      </b-collapse>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <!------------------------------------------------------------------ENTREGABLES--------------------------------------------------------->
              <b-row  v-if="infoCurrentTramite.estado == 'INICIADO' || infoCurrentTramite.estado == 'POR REVISAR' || infoCurrentTramite.estado == 'PENDIENTE PAGO'"><b-col>
                <b-button disabled :style="btnCollapseStepInactive"><b-icon shift-v="-3"  font-scale="2" icon="check-circle-fill" variant="secondary"/> 3 - Entrega de Documentos
                  <span class="when-closed"> <b-icon font-scale=".9" shift-v="2" icon="caret-down-fill"/></span>
                </b-button>
              </b-col></b-row>
              <b-row v-if="infoCurrentTramite.estado != 'INICIADO' && infoCurrentTramite.estado != 'POR REVISAR' && infoCurrentTramite.estado != 'PENDIENTE PAGO'" >
                <b-col>
                  <b-button block v-b-toggle="'collapse-3'" :style="btnCollapseStepActive">
                    <b-icon shift-v="-3"  font-scale="2" icon="check-circle-fill" variant="success"/> 3 - Entrega de Documentos
                    <span class="when-open"> <b-icon font-scale=".9" shift-v="2" icon="caret-up-fill"/></span> 
                    <span class="when-closed"> <b-icon font-scale=".9" shift-v="2" icon="caret-down-fill"/></span>
                  </b-button>
                  <b-collapse id="collapse-3" style="width: 100%;" accordion="my-accordion" role="tabpanel">
                    <div>
                      <div v-for="entregable in infoCurrentTramite.entregables" :key="entregable.idEntregable">
                        <br>
                        <!---- FORM FILE ---->
                        <b-row v-if="entregable.contenidoVacio"  align-h="center" align="center">
                          <b-col cols="12" md="8"><h6><b-icon icon="file-text"/> {{entregable.nombreEntregable}}</h6> </b-col>
                          <b-col cols="12" md="8"><b-form-file placeholder="Sube un archivo" size="sm" :disabled="infoCurrentTramite.estado == 'CONCLUIDO' || infoCurrentTramite.estado == 'CANCELADO'" :id="entregable.idEntregable" :name="entregable.nombreEntregable"  @change="postEntregable"/></b-col>
                          <b-col cols="12" md="8" style="margin-top:7px;"><b-button pill size="md" variant="warning">O bien, Generar documento <b-icon icon="arrow-right-circle"/></b-button></b-col>
                        </b-row>

                        <b-row v-if="!entregable.contenidoVacio" align-h="center" align="center">
                          <b-col cols="12" md="8"><h6><b-icon icon="file-text"/> {{entregable.nombreEntregable}}<span v-if="entregable.fechaEntregado != null">({{entregable.fechaEntregado}})</span></h6></b-col>
                          <b-col cols="12" md="8">
                            <b-button size="sm" variant="link" @click="descargarEntregable(entregable.idEntregable, entregable.nombreEntregable)">Descargar</b-button>
                            <b-button size="sm" v-if="infoCurrentTramite.estadoEnts == 'GENERADO'"  variant="link" @click="eliminarEntregable(entregable.idEntregable)" >Eliminar</b-button>
                          </b-col>
                          <b-col cols="12" md="8"><span style="color:green;" v-if="entregable.fechaRecibido!=null && entregable.fechaRecibido != ''">Este documento ha sido recibido. </span>{{entregable.fechaRecibido}}</b-col>
                        </b-row>

                        <!-- FIRMA -->
                        <b-row align-h="center" align="center" v-if="entregable.linkFirma!=null && entregable.firmado==false && entregable.tipo=='F'">
                          <b-col><b-button pill size="md" @click="mostrarModalFirma(entregable.linkFirma)" variant="info">Firmar Documento <b-icon icon="pen"></b-icon></b-button></b-col>
                          <b-col cols="12"><br><br></b-col>
                        </b-row>
                      </div>
                      <br>
                      <b-row align-h="center">
                        <b-col style="margin-bottom:15px;" cols="12" md="8">
                          <b-button pill block v-if="infoCurrentTramite.estadoEnts == 'GENERADO' && infoCurrentTramite.transaccional" variant="warning" >Generar Cita Presencial <b-icon icon="arrow-right-circle"/></b-button>
                        </b-col>  
                        <b-col cols="12" md="8">
                          <b-button pill block v-if="infoCurrentTramite.estadoEnts == 'GENERADO'" variant="success" @click="notificarCambios('D')">Enviar Documento <b-icon icon="arrow-right-circle"/></b-button>
                        </b-col>            
                      </b-row>
                    </div>
                  </b-collapse>
                </b-col>
              </b-row>
            </b-tab>

            <!----------- EXPEDIENTE ---------->
            <b-tab >
              <template #title >                
                <span style="font-size:90%;"><b-icon scale=".9" icon="archive"></b-icon> Expediente</span>
              </template>
              <b-row >
                <b-col cols="12">
                  <br>
                  <div>
                    <b-card class="c-shadow-sm-gray" >
                        <span style="color:purple; font-size:medium;">Envia un comentario</span><br>
                        <b-form-textarea v-model.trim="textoComentario" placeholder="Escribe aquí" rows="3" max-rows="5"></b-form-textarea>
                        <b-form-file accept="application/pdf" style="margin-top:5px; margin-bottom:5px;" v-model="fileComentario"  placeholder="Selecciona un archivo"></b-form-file>
                        <b-button size="sm" v-if="fileComentario!=null" variant="link" @click="fileComentario=null">Quitar archivo</b-button><br>
                        <b-button v-if="fileComentario==null"  :disabled="textoComentario==null || textoComentario.length<5" variant="info">Enviar <b-icon icon="arrow-right-circle"/></b-button>
                        <b-dropdown v-if="fileComentario!=null" variant="info">
                          <template #button-content>
                            Firmar y Enviar <b-icon icon="pen" aria-hidden="true"></b-icon>
                          </template>
                          <b-dropdown-item>Enviar sin firma <b-icon icon="arrow-right-circle"/></b-dropdown-item>
                        </b-dropdown>
                        
                    </b-card>     
                  </div>
                  <br>
                  <div  v-for="registro in infoCurrentTramite.registros" :key="registro.id+'sf'">
                      <b-card class="c-shadow-sm-gray" v-if="registro.link!=null">
                      <span style="color:red; font-size:small;">Tienes un documento por firmar</span><br>
                      <span style="font-style: oblique;" v-if="registro.mensaje!=null">{{registro.mensaje}}<br></span>
                      <b-button variant="link" v-if="registro.nombreArchivo!=null">{{registro.nombreArchivo}}</b-button><br v-if="registro.nombreArchivo!=null">
                      <b-button>Firmar <b-icon icon="pen"/></b-button>
                    </b-card>
                  </div>  
                  <br>      
                  <h5>Expediente Histórico</h5>
                  <div   v-for="registro in infoCurrentTramite.registros" :key="registro.id">
                      <b-card class="c-shadow-sm" v-if="registro.link==null">
                      <span style="color:blue; font-size:small;">{{registro.autor}}</span><br>
                      <span style="font-style: oblique;" v-if="registro.mensaje!=null">{{registro.mensaje}}<br></span>
                      <b-button variant="link" v-if="registro.nombreArchivo!=null">{{registro.nombreArchivo}}</b-button><br v-if="registro.nombreArchivo!=null">
                      <span  style=" font-size:small;">{{registro.fecha}} </span>
                    </b-card>
                  </div>  
                  <br>             
                </b-col>          
              </b-row>
            </b-tab>
          </b-tabs>
        </div>
      </b-col>
    </b-row>

    <b-row align-h="center" v-if="infoCurrentTramite.estado != 'CANCELADO' && (infoCurrentTramite.estadoEnts != 'LIBERADO' && infoCurrentTramite.estadoEnts != 'RECIBIDO' )">
      <!------  Ya no se usa ya que la finalizacion del trámite la hace automaticamente el sistema cuando el usuario descargar el entregable
        <b-button class="button-finalizar" variant="success" @click="cerrarTramite">Finalizar Trámite</b-button> 
      --->
      <b-button variant="danger" @click="isShowModalConfirmCancel=true">Cancelar Trámite</b-button>
    </b-row>

    <!------------------------------------------------------------------modals--------------------------------------------------------------->
    
    <b-modal v-model="isShowModalViewFile" size="lg" scrollable modal-class="file-modal" hide-footer >
        <vue-pdf-app v-if="archivoDescargado.tipo=='application'" :config="configPdf"  :pdf="'data:'+archivoDescargado.tipo+'/'+archivoDescargado.texto+';base64,' + archivoDescargado.b64"></vue-pdf-app>
        <b-row> 
          <b-col cols="12" v-if="archivoDescargado.tipo=='image'" >
            <img  width="100%;" :src="'data:'+archivoDescargado.tipo+'/'+archivoDescargado.texto+';base64,' + archivoDescargado.b64" />
            <!--
              por el momento no funciona
              <b-button block variant="primary" @click="downloadReq(archivoDescargado.texto)" size="lg">DESCARGAR</b-button>-->
          </b-col>
        </b-row>      
    </b-modal>

    <b-modal title="Ubicación" v-model="isShowModalMaps" size="lg" hide-footer>
      <div>
        <b-row>
          <b-col>
            <GmapMap ref="mapreq" :center="centerMap" :zoom="17" map-type-id="hybrid" style="width: 100%; height: 700px">
              <GmapMarker key="marker" :position="centerMarker"/>
            </GmapMap>
          </b-col>
        </b-row>
      </div>
    </b-modal>

    <b-modal v-model="isShowModalSolicitud" size="lg" title="Solicitud Electrónica" scrollable modal-class="file-modal" hide-footer >
        <b-row  v-if="solicitudAdmin!=null && solicitudAdmin.identificacion!=null && solicitudAdmin.identificacion.b64Front!=null" align-h="center" align="center">
          <b-col cols="11" sm="10" lg="5"><img  width="100%;" :src="'data:media/'+solicitudAdmin.identificacion.extension+';base64,' + solicitudAdmin.identificacion.b64Front" /></b-col>
          <b-col cols="11" sm="10" lg="5"><img  width="100%;" :src="'data:media/'+solicitudAdmin.identificacion.extension+';base64,' + solicitudAdmin.identificacion.b64Back" v-if="solicitudAdmin.identificacion.b64Back!=null"/></b-col>
          <b-col cols="12"><br><br></b-col>
        </b-row>
        <vue-pdf-app v-if="solicitudAdmin!=null && solicitudAdmin.b64Solicitud!=null" :config="configPdf"  :pdf="'data:application/pdf;base64,' + solicitudAdmin.b64Solicitud"></vue-pdf-app>        
    </b-modal>

    <b-modal hide-header no-close-on-backdrop no-close-on-esc v-model="isShowModalConfirmChangeStatusReq" size="sm" hide-footer>
      <div>Desea cambiar el estado del requisito a {{statusOfReqToUpdate}}</div>
      <b-row align-h>
        <b-col sm="6" cols="5">
          <b-button block variant="success" @click="checkIfUpdateEstadoRequisito(idReqToUpdate)" size="sm">Si</b-button>
        </b-col>
        <b-col sm="6" cols="5">
          <b-button block variant="danger" @click="cancelarOperacionReqs()" size="sm">No</b-button>
        </b-col>
      </b-row>
    </b-modal>

     <b-modal hide-header no-close-on-backdrop no-close-on-esc  v-model="isShowModalConfirmAllReqsAcepted" size="md" hide-footer>
      <div>Estas a punto de ACEPTAR todos los requisitos. <br>Después de esto ya no podrás realizar ningun cambio. <br>¿Deseas Continuar?</div>
      <b-row>
        <b-col sm="6" cols="5">
          <b-button block variant="success" @click="updateEstadoRequisito(idReqToUpdate, true)" size="sm">Si</b-button>
        </b-col>
        <b-col sm="6" cols="5">
          <b-button block variant="danger" @click="cancelarOperacionReqs()" size="sm">No</b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal  hide-header v-model="isShowModalConfirmCancel" size="md" hide-footer>
      <div>¿Desea CANCELAR el trámite?.<br>Esta acción no podrá revertise</div>
      <b-row align-h="between">
        <b-col cols="12">
          <br>
          <b-form-textarea v-model.trim="motivoCancelacion"  placeholder="Ingresa el motivo de cancelación"  rows="3" max-rows="5"></b-form-textarea><br>
        </b-col>
        <b-col sm="6" cols="5">
          <b-button :disabled="motivoCancelacion==null || motivoCancelacion.length<10" block variant="success" @click="cancelarTramite" size="sm">Si</b-button>
        </b-col>
        <b-col sm="6" cols="5">
          <b-button block variant="danger" @click="isShowModalConfirmCancel=false" size="sm">No</b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal  hide-header v-model="isShowModalErrorFile" size="sm" hide-footer>
      <div>{{msgErrorFile}}</div>
    </b-modal>

    <b-modal modal-class="firma-modal" v-model="isShowModalFirmaAdmin" scrollable hide-footer @hide="getCurrentService()" >
      <template #modal-title><h3>Firma Electrónica</h3></template>
      <template #modal-header-close><b-button size="md" variant="danger" >Cerrar</b-button></template>
      <b-row>
        <b-col>
          <b-embed type="embed" :src="urlFirmaAdmin" allowfullscreen  ></b-embed>
        </b-col>
      </b-row>
    </b-modal>

    <!---
    
            <div v-if="!infoCurrentTramite.EnLinea && entregable.isContenidoVacio">
              Fecha y Hora de Entrega:
              <b-button :disabled="infoCurrentTramite.estado == 'CONCLUIDO' || infoCurrentTramite.estado == 'CANCELADO' || $v.infoCurrentTramite.obsEntregable.$invalid" variant="primary" size="sm" @click="guardarObsEntsTram">Guardar</b-button>
              <b-form-textarea :disabled="infoCurrentTramite.estado == 'CONCLUIDO' || infoCurrentTramite.estado == 'CANCELADO'" class="text-area" :state="!$v.infoCurrentTramite.obsEntregable.$invalid" v-model.trim="$v.infoCurrentTramite.obsEntregable.$model"></b-form-textarea>
              <div class="error" v-if="!$v.infoCurrentTramite.obsEntregable.minLength">La descripción es muy corta</div>
              <div class="error" v-if="!$v.infoCurrentTramite.obsEntregable.maxLength">La descripción es muy larga</div>
              <div class="error" v-if="!$v.infoCurrentTramite.obsEntregable.required">Este campo es obligatorio.</div>
            <br>
            </div>  
    --->
  </b-container>
</template>

<script>
  import { mapState} from 'vuex';
  import { minLength, maxLength, required } from 'vuelidate/lib/validators';
  import { updateObsReqsAdmin, updateObsEntsAdmin, updateEstadoReqAdmin, downloadReqAdmin, notificarCambioAdmin,
            updateOrdenPagoAdmin, altaEntregableAdmin, eliminaEntregableAdmin, descargarEntregableAdmin,
            cerrarTramiteAdmin, cancelarTramiteAdmin, currentTramiteAdmin, descargarSolicitudAdmin} from '@/axios-tools';
  import {getClassStatusTramite} from '@/vue-tools';
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import Vue from 'vue';
   import * as VueGoogleMaps from 'vue2-google-maps' 
  Vue.use(VueGoogleMaps, {
    load: {
      key: 'AIzaSyD4T_Xis3hk4odIjra8zHQIMksnot0N5i8',
  }})
  import VuePdfApp from "vue-pdf-app";
  // import this to use default icons for buttons
  import "vue-pdf-app/dist/icons/main.css";

  export default {
    computed: {
      ...mapState({
        token: state => state.admin.token,
        filesB64: state => state.admin.filesB64,
        isWorkingAdmin: state => state.admin.isWorkingAdmin,
        isWorkingAdminModals: state => state.admin.isWorkingAdminModals,
        archivoDescargado: state => state.admin.archivoDescargado,
        porRevisarInfo: state => state.admin.porRevisarInfo,
        principalColor: state => state.user.principalColor,
      }),
      isShowModalConfigTramite: {
        get: function() {
          return this.$store.state.admin.isShowModalConfigTramite;
        },
        set: function(newIsShowModalConfigTramite) {
          this.$store.commit("updateIsShowModalConfigTramite", newIsShowModalConfigTramite);
        }
      },
      infoCurrentTramite: {
        get: function() {
          return this.$store.state.admin.infoCurrentTramite;
        },
        set: function(newInfo) {
          this.$store.commit("updateInfoCurrentTramite", newInfo);
        }
      },
      serie: {
        get: function() {
          return this.$store.state.admin.serie;
        },
        set: function(newSerie) {
          this.$store.commit("updateSerie", newSerie);
        }
      },
      folio: {
        get: function() {
          return this.$store.state.admin.folio;
        },
        set: function(newFolio) {
          this.$store.commit("updateFolio", newFolio);
        }
      },
      isShowModalViewFile: {
        get: function() {
          return this.$store.state.admin.isShowModalViewFile;
        },
        set: function(newIsShowModalViewFile) {
          this.$store.commit("updateIsShowModalViewFile", newIsShowModalViewFile);
        }
      },
      isShowModalSolicitud: {
        get: function() {
          return this.$store.state.admin.isShowModalSolicitud;
        },
        set: function(newIs) {
          this.$store.commit("updateIsShowModalSolicitud", newIs);
        }
      },
      solicitudAdmin: {
        get: function() {
          return this.$store.state.admin.solicitudAdmin;
        },
        set: function(newIs) {
          this.$store.commit("updateSolicitudAdmin", newIs);
        }
      },
      isShowModalFirmaAdmin:{
        get: function() {
          return this.$store.state.admin.isShowModalFirmaAdmin;
        },
        set: function(newIs) {
          this.$store.commit("updateIsShowModalFirmaAdmin", newIs);
        }
      },
      urlFirmaAdmin:{
        get: function() {
          return this.$store.state.admin.urlFirmaAdmin;
        },
        set: function(newUrl) {
          this.$store.commit("updateUrlFirmaAdmin", newUrl);
        }
      },
      btnCollapseStepActive: function(){
        return 'margin-left:-7px; text-align:left; background-color:rgb(1,1,1,0); border:white;'+'color:'+this.principalColor+';' + 'font-size:17px; font-weight: bolder; box-shadow:none;';
      },
      btnCollapseStepInactive: function(){
        return 'margin-left:-7px; text-align:left; background-color:rgb(1,1,1,0); border:white;'+'color:gray; font-size:17px; font-weight: bolder';
      },
    },
    components: {
      Loading,
      VuePdfApp
    },
    data() {
      return {
        verObs:false,
        isShowModalConfirmChangeStatusReq:false,
        isShowModalConfirmAllReqsAcepted: false,
        isShowModalConfirmCancel: false,
        statusOfReqToUpdate:"",
        isShowModalErrorFile:false,
        isShowModalMaps:false,
        centerMap:{lat: 19.4679606,lng: -99.1558783},
        centerMarker:{lat: 19.4679606,lng: -99.1558783},
        msgErrorFile:"",
        idReqToUpdate:null,
        solicitudOptions:[
                          {value:'POR REVISAR',text:'POR REVISAR'},
                          {value:'ACEPTADO',text:'ACEPTADO'},
                          {value:'NO ACEPTADO',text:'NO ACEPTADO'}
                          ],
        cliente: location.pathname.split("/", 2).toString().replace(",", ""),
        fileObs:null,
        fileObsB64:null,
        motivoCancelacion:'',
        fileComentario:null,
        textoComentario:null,
        configPdf:{
          toolbar:{
            toolbarViewerRight:{
              openFile:false
            }
          }
        }
      }
    },
    methods: {
      getClassStatusTramite : getClassStatusTramite,
      isTramiteNoOperable(){ //Aun no se usa
        return this.infoCurrentTramite.estado == 'CONCLUIDO' || this.infoCurrentTramite.estado == 'CANCELADO'
      },
      getCurrentService(){
        currentTramiteAdmin(this.cliente, this.token, this.infoCurrentTramite.idUserService, this.porRevisarInfo.idServiceInfo, this.$store, this.$router);
      },
      cancelarOperacionReqs(){
        this.isShowModalConfirmChangeStatusReq=false;
        if(this.isAllReqsAcepted()){
          this.isShowModalConfirmAllReqsAcepted=false;
        }
        this.getCurrentService(); //Se actualiza nuevmente el modelo
      },
      downloadReq(tipo){
        var downloadPdf = document.createElement('a');
        downloadPdf.setAttribute('href', "data:application/pdf;base64," + this.archivoDescargado);
        downloadPdf.setAttribute('download', "requisito."+tipo);
        downloadPdf.click();
      },
      cancelarTramite(){
        cancelarTramiteAdmin(this.cliente, this.token, this.infoCurrentTramite.idUserService, this.$store, this.$router, this.motivoCancelacion);
        this.isShowModalConfirmCancel=false;
        this.motivoCancelacion=''
      },
      cerrarTramite(){
        cerrarTramiteAdmin(this.cliente, this.token, this.infoCurrentTramite.idUserService, this.$store, this.$router);
      },
      descargarEntregable(idEntregable, nombreEntregable){
        descargarEntregableAdmin(this.cliente, this.token, this.infoCurrentTramite.idUserService, idEntregable, this.$store, nombreEntregable, this.$router);
      },
      eliminarEntregable(idEntregable){
        eliminaEntregableAdmin(this.cliente, this.token, idEntregable, this.$store, this.infoCurrentTramite.idUserService, this.$router);
      },
      postEntregable(evt){
        //this.$store.commit("updateIsWorkingAdmin", true);
        var f = evt.target.files[0];
        var reader = new FileReader();
        var idEntregable = evt.target.id;
        //Creamos una refrencia local al objeto this para poder acceder desde las funciones anidadas a los objetos cliente, token .. etc
        var thisObject = this;
        reader.onload = (function() {
            return function(theFile) {
            var binaryData = theFile.target.result;
            // Convertir datos binarios a base 64
            var base64String = window.btoa(binaryData);
            // mostrando el archivo convertido a base64
            var tipo = f.name.split('.').pop();
            if(tipo == 'pdf'){
              if(f.size <= 2000000){
                altaEntregableAdmin(thisObject.token, thisObject.infoCurrentTramite.idUserService, idEntregable, base64String, tipo, thisObject.$store, thisObject.$router);
              }else{
                thisObject.msgErrorFile="El documento no debe exceder 2mb de tamaño";
                thisObject.isShowModalErrorFile=true;
              }
            }else{
              thisObject.msgErrorFile="La extensión del archivo no es válida";
              thisObject.isShowModalErrorFile=true;
            }            
          };
        })(f);
        reader.onerror = function(error){
          alert("error al cargar archivo "+error);
        };
        // Leer en el archivo de imagen como una URL de datos.
        reader.readAsBinaryString(f);
      },
      saveB64Obs(evt){
        var f = evt.target.files[0];
        var reader = new FileReader();
        var thisObject = this;
        reader.onload = (function() {
            return function(theFile) {
            var binaryData = theFile.target.result;
            var base64String = window.btoa(binaryData);
            var tipo = f.name.split('.').pop();
            if(tipo == 'pdf'){
              if(f.size <= 2000000){
                thisObject.fileObsB64 = base64String
              }else{
                alert('El archivo debe ser menor a 2mb');
              }
            }else{
              alert('Debes seleccionar una archivo pdf');
            }            
          };
        })(f);
        reader.onerror = function(error){
          alert("error al cargar archivo"+error);
        };
        // Leer en el archivo de imagen como una URL de datos.
        reader.readAsBinaryString(f);
      },
      updateOrdenPago(){
        updateOrdenPagoAdmin(this.token, this.infoCurrentTramite.idUserService, this.serie, this.folio, this.$store, this.$router);
      },
      notificarCambios(tipo){
        notificarCambioAdmin(this.cliente, this.token, this.infoCurrentTramite.idUserService, tipo, this.$store, this.$router);        
      },
      downloadRequisito(idRequisito, nombreRequisito){
        downloadReqAdmin(this.cliente, this.token, this.infoCurrentTramite.idUserService, idRequisito, this.$store, nombreRequisito, this.$router);
      },
      showModalConfirmChangeStatusReq(idRequisito){
        var idxRequisito = this.getIdxFromArrayRequisitos(idRequisito);
        this.statusOfReqToUpdate=this.infoCurrentTramite.requisitos[idxRequisito].estado;
        this.idReqToUpdate=idRequisito;
        this.isShowModalConfirmChangeStatusReq=true;
      },
      checkIfUpdateEstadoRequisito(idRequisito){
        if(this.isAllReqsAcepted()){
          this.isShowModalConfirmAllReqsAcepted = true;          
        }else{
          this.updateEstadoRequisito(idRequisito, false);
        }        
      },
      updateEstadoRequisito(idRequisito, isAllReqsAcepted){
        var idxRequisito = this.getIdxFromArrayRequisitos(idRequisito);
        if(this.infoCurrentTramite.requisitos[idxRequisito].estado != 'PENDIENTE'){
          updateEstadoReqAdmin(this.token, idRequisito, this.infoCurrentTramite.requisitos[idxRequisito].estado, this.$store, isAllReqsAcepted, this.isAllReqsEmpty(), this.infoCurrentTramite.idUserService, this.$router);
        }
        this.isShowModalConfirmChangeStatusReq=false;
        if(isAllReqsAcepted){
          this.isShowModalConfirmAllReqsAcepted=false;
        }
      },
      isAllReqsAcepted(){       
        for(var i=0; i< this.infoCurrentTramite.requisitos.length; i++){
          if(this.infoCurrentTramite.requisitos[i].estado != "ACEPTADO" && !this.infoCurrentTramite.requisitos[i].opcional){
            return false;
          }
        }   
        return true;
      },
      isAllReqsEmpty(){       
        for(var i=0; i< this.infoCurrentTramite.requisitos.length; i++){
          if(this.infoCurrentTramite.requisitos[i].estado != "NO ACEPTADO" && this.infoCurrentTramite.requisitos[i].estado != "PENDIENTE" && !this.infoCurrentTramite.requisitos[i].opcional){
            return false;
          }
        }   
        return true; 
      },
      guardarObsEntsTram(){
        updateObsEntsAdmin(this.cliente, this.token, this.infoCurrentTramite.idUserService, this.infoCurrentTramite.obsEntregable, this.$store, this.$router);
      },
      guardarObsReqsTram(tipoObs){
        var nFile =null;
        if(tipoObs!='txt'){
          nFile ="Observaciones.pdf";
        }
        updateObsReqsAdmin(this.cliente, this.token, this.infoCurrentTramite.idUserService, this.infoCurrentTramite.obsRequisitos, this.fileObsB64, nFile, tipoObs=='txt_file_sign', this.$store, this.$router);
        this.fileObsB64 =null
        this.fileObs =null
        this.infoCurrentTramite.obsRequisitos=null;
      },
      getIdxFromArrayRequisitos(idRequisito){
        for(var idx = 0; this.infoCurrentTramite.requisitos.length > idx; idx++){
          if(this.infoCurrentTramite.requisitos[idx].idRequisito == idRequisito){
            return idx;
          }
        }
      },
      mostrarModalFirma(url){
        this.$store.commit("updateIsShowModalFirmaAdmin", true);
        this.$store.commit("updateUrlFirmaAdmin", url);
      },
      pagesRendered(pdfApp) {
        setTimeout(() => (pdfApp.pdfViewer.currentScaleValue = "page-fit"));
      },
      mostrarSolicitudAdmin(){
        descargarSolicitudAdmin(this.token, this.infoCurrentTramite.idUserService, this.$store, this.$router);
      },
      verModalMapaRequisito(){
        this.isShowModalMaps=true;
        
        this.centerMarker={lat: 19.4679606,lng: -99.1558783};
        this.centerMap={lat: 19.4679606,lng: -99.1558783}
        
      },
    },
    validations: {
      serieC:{
        required,
        minLength: minLength(3),
        maxLength: maxLength(3)
      },
      folioC:{
        required,
        minLength: minLength(1),
        maxLength: maxLength(10)
      },
      serie:{
        required,
        minLength: minLength(3),
        maxLength: maxLength(3)
      },
      folio:{
        required,
        minLength: minLength(1),
        maxLength: maxLength(10)
      },
      infoCurrentTramite:{
        obsRequisitos:{
          required,
          minLength: minLength(5),
          maxLength: maxLength(200)
        },
        pago:{
          ordenPago:{
            required,
            minLength: minLength(1),
            maxLength: maxLength(10)
          }
        },
        obsEntregable:{
          required,
          minLength: minLength(5),
          maxLength: maxLength(200)
        }
      }
    }
  }
</script>
<style>
.text-req-opcional{
  color:#D932AA; 
  margin-left:15px;
}
.green{
  color:#0C8004;
}
.input-contenido-req{
  margin-top:5px;
}
.c-shadow-sm-gray{
  background-color:#f3f3f3;
  box-shadow: 7px 7px 15px 2px #B5B5B5;
  margin-bottom:10px;
}
div.error{
  color: red;
}
.margin{
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left:-20px;
  margin-right:-20px;
}
.button-registrar-orden{
  margin-left:10px;
}
.row-input-orden-pago{
  margin-top:10px;
}
.button-finalizar{
  margin-right:10px;
}
.firma-modal .modal-dialog {
    max-width: 100% ;
}
.file-modal .modal-dialog {
    height:100% !important;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: 'Buscar';
}
</style>